.container {
  margin-top: var(--spacing-top); /* Dev default as part of margin refactor */
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-sm);

  @media (width >= 1000px) {
    max-width: var(--max-width-base);
  }
}

.card {
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  padding-inline: 24px;
  padding-top: 40px;
  padding-bottom: 24px;
  text-align: center;

  @media (width >= 650px) {
    padding-top: 60px;
  }

  @media (width >= 1000px) {
    grid-template-columns: repeat(12, 1fr);
    background-color: transparent;
    grid-gap: 24px;
    display: grid;
    grid-auto-flow: dense;
    grid-column: 1/-1;
    padding: 0;
    text-align: initial;
  }
}

.content {
  display: flex;
  position: relative;
  grid-column: 1/-1;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  margin-bottom: 30px;

  @media (width >= 1000px) {
    grid-row: 1;
    grid-column: 8/-1;
    align-items: flex-start;
    align-self: center;
    margin-top: 60px;
    margin-bottom: 55px;
  }
}

@media (width >= 1000px) {
  [data-has-custom-theme] .kicker {
    --kicker-color: var(--page-theme-contrast-color, unset);
    --kicker-border-color: var(--theming-kicker-border-color);
  }
}

.title {
  display: block;
  margin-top: 27px;
  margin-bottom: 20px;
  max-width: 247px;
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-28px);
  line-height: 1.22;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    max-width: 330px;
    font-size: var(--font-size-36px);
  }

  @media (width >= 1000px) {
    max-width: 430px;
    color: var(--page-theme-contrast-color, var(--color-text-heading));
    font-size: var(--font-size-48px);
  }
}

.text {
  max-width: 428px; /* todo: investigate why this is 2px smaller than the title? */
  color: var(--color-text-subtle);
  line-height: 1.5;

  @media (width >= 1000px) {
    color: var(--page-theme-contrast-color, var(--color-text-subtle));
    font-size: var(--font-size-21px);
    line-height: 1.7;
  }
}

.text--bold {
  font-weight: bold;
}

.text--italic {
  font-style: italic;
}

.button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: var(--button-wrapper-block-start);

  @media (width >= 650px) {
    flex-direction: row;
  }
}

@media (width >= 1000px) {
  [data-has-custom-theme] .button[kind="link"] {
    --cosmos-button-color: var(--theming-link-button-color);
    --cosmos-button-color-hover: var(--theming-link-button-color-hover);
    --cosmos-button-border-color: var(--theming-link-button-border-color);
    --cosmos-button-border-color-hover: var(
      --theming-link-button-border-color-hover
    );
  }

  [data-has-custom-theme] .button[kind="tertiary"] {
    --cosmos-button-background: var(--theming-tertiary-button-background);
    --cosmos-button-background-hover: var(
      --theming-tertiary-button-background-hover
    );
    --cosmos-button-color: var(--theming-tertiary-button-color);
    --cosmos-button-color-hover: var(--theming-tertiary-button-color-hover);
    --cosmos-button-border-width: var(--theming-tertiary-button-border-width);
    --cosmos-button-border-color: var(--theming-tertiary-button-border-color);
    --cosmos-button-border-color-hover: var(
      --theming-tertiary-button-border-color-hover
    );
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

html[dir="rtl"] .button-icon {
  transform: scaleX(-1);
}

.media-wrapper {
  display: block;
  grid-column: 1 / -1;

  @media (width >= 1000px) {
    display: flex;
  }

  @media (width >= 1000px) {
    /* The edge of this wrapper ends at 6.5 cols. Causing issues with the grid layout.
       This container overlaps the `content` as it's set to the same `grid-row`.
       Then we ~54% the width to simulate it being 6.5 cols with no gutter */

    grid-row: 1;
    grid-column: 1 / -1;
    border-radius: var(--radius-large);
    background-color: var(--color-surface-solid-light-lighter);
    width: 54.1666666%; /* 6.5 col / 12 cols */
    overflow: hidden; /* stops video overlapping corner in rare circumstances */
  }
}

.asset {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (width >= 1170px) {
    padding: 20px;
    object-fit: cover;
  }
}
